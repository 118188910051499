<template>
	<v-container id="collection" fluid tag="section">
		<loader :active="loaderActive" />
		<v-row>
			<v-col cols="12" sm="6" lg="">
				<base-material-stats-card
					color="primary"
					icon="mdi-bag-carry-on-check"
					title="Total ITD"
					:value="
						responsePayload.total ? responsePayload.total.toString() : '0'
					"
				/>
			</v-col>

			<v-col cols="12" sm="6" lg="3">
				<base-material-stats-card
					color="red"
					icon="mdi-block-helper"
					title="Failed ITD"
					:value="
						responsePayload.failedItd
							? responsePayload.failedItd.toString()
							: '0'
					"
				/>
			</v-col>

			<v-col cols="12" sm="6" lg="3">
				<base-material-stats-card
					color="success"
					icon="mdi-check-all"
					title="Successful ITD"
					:value="
						responsePayload.successfulItd
							? responsePayload.successfulItd.toString()
							: '0'
					"
				/>
			</v-col>
			<v-col cols="12" sm="6" lg="3">
				<base-material-stats-card
					color="orange"
					icon="mdi-update"
					title="Pending Txns"
					:value="
						responsePayload.pending ? responsePayload.pending.toString() : '0'
					"
				/>
			</v-col>
			<v-col cols="12" md="12">
				<v-row rows="12" justify="end"> </v-row>
			</v-col>
			<v-col cols="12" md="12">
				<base-material-card color="primary" class="px-5 py-3">
					<template v-slot:heading>
						<div class="display-2 font-weight-dark lightgray--text">
							Transaction List
							<button class="float-right">Download</button>
							<v-spacer />
							<v-text-field
								v-model="search"
								append-icon="mdi-magnify"
								label="Search"
								single-line
								hide-details
								color="lightgray"
							/>
						</div>
					</template>
					<v-card-text>
						<v-data-table
							:headers="headers"
							:items="items"
							:loading="loaderActive"
							:search="search"
							item-key="id"
							:sort-by.sync="sortBy"
							:sort-desc.sync="sortDesc"
							class="elevation-1"
							@click:row="(item, slot) => showDetails(item)"
							@dblclick:row="editItem(item)"
						>
							<template v-slot:item.status.name="{ item }">
								<div
									:class="{
										'grey--text font-weight-normal': item.status.id === 4,
										'orange--text font-weight-normal': item.status.id === 3,
										'red--text font-weight-normal': item.status.id === 2,
										'green--text font-weight-normal': item.status.id === 1,
									}"
								>
									{{ item.status.name }}
								</div>
							</template>
							<template v-slot:item.amount="{ item }">
								<div
									:class="{
										'grey--text font-weight-regular': item.status.id === 4,
										'orange--text font-weight-regular': item.status.id === 3,
										'red--text font-weight-regular': item.status.id === 2,
										'green--text font-weight-regular': item.status.id === 1,
									}"
								>
									K{{ item.amount }}
								</div>
							</template>
						</v-data-table>
						<!-- Transaction Modal -->
						<TransactionModal
							:transaction="selectedTransaction"
							ref="transactionModal"
						></TransactionModal>
					</v-card-text>
				</base-material-card>
			</v-col>
		</v-row>
	</v-container>
</template>
<script>
	import store from "../../../../store";
	import loaderMixin from "../../../../mixins/loader";
	export default {
		components: {
			Loader: () => import("../../../../components/Loader"),
			TransactionModal: () => import("../../components/TransactionModal"),
		},
		mixins: [loaderMixin],
		data: () => ({
			search: "",
			expanded: [],
			sortBy: "id",
			sortDesc: true,
			singleExpand: false,
			responsePayload: {},
			headers: [
				{
					sortable: true,
					text: "Date",
					value: "createdAt",
					align: "left",
				},
				{
					sortable: true,
					text: "Last Name",
					value: "customer.lastName",
				},
				{
					sortable: true,
					text: "First Name",
					value: "customer.firstName",
				},
				{
					sortable: false,
					text: "Type",
					value: "transactionType.name",
				},
				{
					sortable: false,
					text: "Status",
					value: "status.name",
				},
				{
					sortable: true,
					text: "External ID",
					value: "externalTransactionID",
				},
				{
					sortable: true,
					text: "Amount(ZMW)",
					value: "amount",
					class: "font-weight-black",
				},
			],
			items: [],
			selectedTransaction: null,
		}),
		created() {
			this.initialize();
		},
		methods: {
			async initialize() {
				this.showLoader();
				await this.$store.dispatch("transaction/fetchTransactions"),
					(this.items = store.state.transaction.transactions.data);
				this.responsePayload = store.state.transaction.transactions;
				this.hideLoader();
			},
			showDetails(selectedRow) {
				this.selectedTransaction = selectedRow; // Set the selected transaction
				this.$refs.transactionModal.openModal(); // Open the modal
			},
		},
		mounted() {
			document.title = "Transactions | Kredwise";
		},
	};
</script>

<style lang="scss" scoped></style>
